<template>
  <div
    :class="'recharge-page ' + LANGCODE"
    :style="'--aog-currency:\'' + SYSLANG.currency + '\''"
  >
    <div class="header">
      <img class="member-bg" :src="require('@/assets/images/bg-user-center.png')" />
      <div class="credit">
        <span class="title">關稅單繳納</span>
		<span class="title">NT${{Amount}}</span>
      <!--  <span class="price">{{ agentInfo.Balance }}</span> -->
      </div>
    </div>

    <div class="credit-block">
      <van-form @submit="onSubmit">
        <div class="van-cell van-field">
          <div class="van-cell__title van-field__label van-field__label--top">
            {{ PAGELANG.收款账户 }}
          </div>
          <template v-for="(account, index) in useraccount" :key="index">
            <div
              class="van-cell__value van-field__value"
              :style="
                'flex: none; width: 100%; text-align: left;' +
                (index == 0 ? '' : 'margin-top:6px;')
              "
            >
			  <div class="van-field__body" style="color: #333">
				  <label>打款人: </label>{{transferUserName}}
			  </div>
			
              <div class="van-field__body" style="color: #333">
                {{ PAGELANG.账户号 }}: {{ account.accountNumber }}<br />
                {{ PAGELANG.开户行 }}: {{ account.accountAddress }}<br />
                {{ PAGELANG.收款人 }}: {{ account.account }}<br />
              </div>
            </div>
          </template>
        </div>
        <van-field
          v-model="Amount"
          name="Amount"
		  disabled=true
          :label="PAGELANG.rechargemoney"
          :placeholder="money"
          label-align="top"
          class="amount"
        />
        <van-field
          name="TradeNo"
          v-model="TradeNo"
          :label="PAGELANG.tradeno"
          :placeholder="PAGELANG.tradenoplaceholder"
          :rules="[{ required: true, message: this.PAGELANG.rulestradeno }]"
          label-align="top"
        />
		<van-field
		  v-model="curPerfix"
		  is-link
		  readonly
		  label="选择打款时间"
		  @click="showPrefix = true"
		/>
		
		<van-popup v-model:show="showPrefix" round position="bottom">	
		 <!-- <van-datetime-picker
		    type="datetime"
		    :value="currentDate"
		    :min-date="minDate"
		    :max-date="maxDate"
		    @confirm="onConfirm"
		    @cancel="onCancel"
		    /> -->
			<van-date-picker
			  v-model="currentDate"
			  title="选择打款时间"
			  :min-date="minDate"
			  :max-date="maxDate"
			  @confirm="onConfirm"
			  @cancel="onCancel"
			  :formatter="formatter"
			  :columns-type="columnsType"
			/>
		  </van-popup>
		
		
        <div class="van-cell van-field">
          <div class="van-cell__title van-field__label van-field__label--top">
            {{ PAGELANG.tradeproof }}
          </div>
          <van-uploader
            v-model="TradeProof"
            multiple
            :max-count="5"
            :upload-text="PAGELANG.tradeprooftext"
            :before-read="beforeRead"
            :after-read="uploadTradeProof"
            class="trade-proof"
          />
          <div
            v-if="showTradeProofMsg"
            class="van-field__error-message"
            style="width: 100%"
          >
            {{ PAGELANG.relestradeproof }}
          </div>
        </div>
        <van-button
          type="primary"
          block
          color="var(--van-card-price-color)"
          native-type="submit"
          class="btn-submit"
          >{{ PAGELANG.submit }}</van-button
        >
      </van-form>
    </div>
	
  </div>


</template>

<script>
import { showLoadingToast, showToast, closeToast,showConfirmDialog } from "vant";
import { doResizeImage, getCookie,setCookie} from "../util/index.js";
import { ref } from 'vue';
export default {
   setup() {
      const currentDate = ref(['2021', '01']);
      const columnsType = ['year', 'month','day'];
  
      const formatter = (type, option) => {
        if (type === 'year') {
          option.text += '年';
        }
        if (type === 'month') {
          option.text += '月';
        }
		if (type === 'day') {
		  option.text += '日';
		}
        return option;
      };
  
      return {
        minDate: new Date(2020, 0, 1),
        maxDate: new Date(2025, 5, 1),
        formatter,
        currentDate,
        columnsType,
      };
    },	
  data() {
    return {
      agentInfo: {},
      Amount:"",
      TradeNo: "",
      TradeProof: [],
      showTradeProofMsg: false,
      useraccount: [],
      chargelimit: 0,
      recharge: {},
      PAGELANG: {},
      rules: {},
      labelWidth: "120px",
	  ticketUrls:[],
	  transferUserName:"",
	  showPrefix:false,
    };
  },
  beforeCreate() {
    if (!getCookie("agentlogintoken")) {
      this.$router.replace({
        path: "/login",
      });
      return;
    }
  },
  created() {
    if (!getCookie("agentlogintoken")) {
      return;
    }
	
	this.axios.get(this.actions.memberInfo).then((response) => {
		  this.transferUserName=response.data.data.realName
	})
	
	
	if (this.$route.query.orderid) {
	  this.orderCode = this.$route.query.orderid;
	}
	
	if (this.$route.query.money) {
	  this.Amount = this.$route.query.money/100;
	}
	

    this.PAGELANG = this.LANG.pages.recharge;
    this.SYSLANG = this.LANG.system;
    this.LANGCODE = this.langcode;

    if (this.LANGCODE != "zh") {
      this.labelWidth = "190px";
    }


    this.getMemberInfo();
  },
  methods: {
	onConfirm(event) {
	  // 获取选中的日期和时间
			let time=event.selectedValues[0]+"-"+event.selectedValues[1]+"-"+event.selectedValues[2]
			time=time+" 00:00:00"
			this.curPerfix=time
			this.tradeDate = (new Date(time.replace(/-/g, "/"))).getTime()/1000
			 console.log('tradeDate',this.tradeDate);
			this.showPrefix=false
	
	},
	onCancel() {
	  // 处理取消操作
	  console.log('取消选择');
			this.showPrefix=false
	},  
	exitHandle(){
	  showConfirmDialog({
	    title: this.SYSLANG.dialogtitle,
	    message: "確定退出重新登錄嘛",
	    confirmButtonText: this.SYSLANG.queding,
	    cancelButtonText: this.SYSLANG.quxiao,
	  })
	    .then(() => {
	      //退出登录
	      setCookie("agentloginid", "");
	      setCookie("agentlogintoken", "");
	      this.$router.push("/login");
	    })
	    .catch(() => {});
	},    
    getMemberInfo() {
      showLoadingToast({
        message: this.SYSLANG.loading,
        duration: 0,
        forbidClick: true,
      });
      this.chargelimit = 30000;
	  closeToast(); 
		   
	  this.axios.get(this.actions.offlineDesc).then(res=>{
		  console.log('asd',res)
		  let arr=[]
		  arr.push(res.data.data.accounts[0])
		  this.useraccount=arr
	  })
    },
    formatMoney(money) {
      if (!money) return "0.00";
      let integer = Math.floor(money).toString();
      let thousands = integer
        .split("")
        .reverse()
        .join("")
        .replace(/(\d{3})/g, "$1.")
        .replace(/,$/, "");

      if (thousands.substr(-1, 1) == ".") {
        thousands = thousands.substring(0, thousands.length - 1);
      }
      return thousands.split("").reverse().join("");
    },
 
    beforeRead(file) {
      if (
        file.type !== "image/jpeg" &&
        file.type !== "image/png" &&
        file.type !== "image/gif"
      ) {
        showToast(this.PAGELANG.uploadfile);
        return false;
      }
      return true;
    },
    uploadTradeProof(file) {
      this.showTradeProofMsg = false;
      console.log(file);
	  const forms = new FormData()
	  forms.append('file', file.file)
	  this.axios.post(this.actions.upload,forms).then(res=>{
		  console.log(res)
		  this.ticketUrls.push(res.data.data.url)
	  })
	  
    },
   

    onSubmit() {
      if (this.TradeProof.length <= 0) {
        this.showTradeProofMsg = true;
        return false;
      }
	  
	  if(this.tradeDate.length<=0){
	  		  showToast({
	  		  	message:"請填寫轉賬時間!",
	  		  });
	  		  return 
	  }

      showLoadingToast({
        message: this.SYSLANG.正在提交申请,
        forbidClick: true,
        duration: 0,
      });

      this.issubmit = true;
      this.showTradeProofMsg = false;
      let TradeProof = [];
      for (let i = 0; i < this.TradeProof.length; i++) {
        doResizeImage(this.TradeProof[i].content, 1000, 1000).then((res) => {
          this.TradeProof[i].content = res;
          TradeProof[i] = res;
          if (i == this.TradeProof.length - 1) {
            let postdata = new Object();
            postdata.TradeProof = TradeProof;
			postdata.ticketUrls=this.ticketUrls,
			postdata.transferDate=this.tradeDate,
			postdata.transferBankAccount="",
			postdata.transferBankName="",
			postdata.transferBankPhone="",
			postdata.transferOrderCode=this.TradeNo, //流水號
			postdata.transferReason="",
			postdata.transferUserIdCard="",
			postdata.transferUserName=this.transferUserName,//打款人名字
			postdata.contractUrl="",

			postdata.orderCode=this.orderCode
			postdata.goodsMoney=this.Amount*100
			postdata.type=0,
			postdata.account=this.useraccount[0].account,
			postdata.accountNumber=this.useraccount[0].accountNumber,
			postdata.accountAddress=this.useraccount[0].account.accountAddress,
			this.axios.post(this.actions.orderTariffPay,postdata).then(res=>{
				  console.log(res)
				  if(res.data.code==200){
					  closeToast();
					  showToast({
					    message:"上傳成功,按進度排單出貨,最新出貨進度請洽發貨中心客服",
					    forbidClick: true,
						onClose: () => {
						  this.$router.replace({
						       path: "/my",
						      });
						   },
					  });
					  
				  }else{
					  showToast({
					    message:res.data.message,
					    forbidClick: true,
					  });
				  }
			})
          }
        });
      }
    },
  },
};
</script>

<style src="../assets/css/recharge.css" scoped></style>
<style scoped>
.recharge-page:not(.zh) .trade-proof >>> .van-uploader__upload-text {
  line-height: 14px;
  text-align: center;
}
  .exit-login {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background-color: var(--van-card-price-color);
      color: #fff;
      font-size: 14px;
      border-radius: 50%;
      position: fixed;
      right: 16px;
      bottom: calc(var(--van-tabbar-height) + 16px);
	  z-index:11;
  }
</style>